import React, { useState } from 'react';
import 'primeicons/primeicons.css';
 
import PieChart from './Chart';
import { Tooltip } from 'primereact/tooltip';
import 'primeicons/primeicons.css'; // icons
import plus from './Assets/plus.svg'
import Tick from "./Assets/darktick.svg"
import  Cross from "./Assets/cross.svg"
import pen from "./Assets/edit.svg"
 
// import 'primereact/resources/primereact.min.css';  
import Toggle from "./Toggle"
import "./primereact-theme/themes/mytheme/theme.scss"

import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useRef } from 'react';  
import { useEffect } from 'react';
import Accordion from './Accordion';
 
function Cell(props) {
  const [inputValue, setInputValue] = useState('');
  const [response, setResponse] = useState(null);
  const [progress, setProgress] = useState(false);
  const [data, setData] = useState("")
  const [selectedView, setSelectedView] = useState('Scores');
  const [submitted,setSubmitted]=useState(false)
  const [errorMessage,setErrorMessage]=useState(null)
  const inputRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  function  handleOpenIndex ( data) {
     setOpenIndex(data)
     
  }
  useEffect(()=>{
     
     if(selectedView=='Scores')
      {
        setOpenIndex(null)
      }
    
  },[selectedView])
   
  useEffect(() => {
    // Focus the input box when the component mounts
    inputRef.current.focus();
  }, []);

  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    // Function to check screen size on load and resize
    const checkScreenSize = () => {
      setIsMediumScreen(window.innerWidth >= 768); // Tailwind md breakpoint
    };

    // Initial check
    checkScreenSize();

    // Event listener for resizing
    window.addEventListener('resize', checkScreenSize);

    // Clean up the event listener
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);
  
  
  
  const handleTitleClick = () => {
    setSubmitted(false);
    inputRef.current.focus();
  };

  const generateTooltipId = (label) => {
    return `tooltip-${label.replace(/[^a-zA-Z0-9-_]/g, '-')}`;
  };

  const handleSubmit = async () => {
    if (!inputValue) {
      return;
    }
    if (!inputValue.trim()) {
      return;
    }
    
    setProgress(true);
    props.setProgressPercent(5);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({ "prompt": inputValue });
    props.setProgressPercent(10);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    props.setProgressPercent(30);
    try {
      const res = await fetch("https://dmnb6b9wgh.execute-api.ap-south-1.amazonaws.com/default/", requestOptions);
      props.setProgressPercent(50);
      const result = await res.json();
      const statusCode=await result.statusCode;
      
      if(statusCode===200)
      {

      
      props.setProgressPercent(70);
      const trueValues = [];
      const falseValues = [];
      props.setProgressPercent(75);
      Object.entries(result.response).forEach(([key, value]) => {
        const item = {
          label: key,
          value: key === 'Self-Interest' ? 20 : 10, // Hardcoded value as per your previous requirement
          attribute: value
        };

        if (value === true) {
          trueValues.push(item);
        } else {
          falseValues.push(item);
        }
      });
      props.setProgressPercent(90);
      const formattedData = [...trueValues, ...falseValues];
      setData(formattedData)
      setResponse(result.response);
      props.setProgressPercent(100);
      setSubmitted(true)
       
    
      props.setProgressPercent(100);
      
      
    }
    else{
      const msg=await result.errorType;
      props.setProgressPercent(100);
      setData(null)
      setResponse(null);
      setProgress(false);
      setErrorMessage(msg)

    }
    } catch (error) {
      console.log('error', error);
      setResponse({ error: 'Error: ' + error.message });
      props.setProgressPercent(0);
    }
    setProgress(false)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
       handleSubmit()
    }
  };
  
const handleSelectAttribute=(index)=>{
  if(selectedView==='Examples')
    {
      setOpenIndex(index)
    }
    else{
      return;
    }
}
  const renderAttribute = (isTrue, label,index) => {
    const isSelfInterest = label === "Self-Interest";
    const tooltipId = generateTooltipId(label);
    let val = 0;
    if (isSelfInterest & isTrue)
      val = 2
    else if (isTrue)
      val = 1
     const tooltipText = isTrue
  ? `${label} factor found. ${val} point${val === 1 ? '' : 's'} given.`
  : label === 'No Exaggeration'
    ? `Exaggeration factor found. No point given.`
    : `${label} factor not found. No point given.`;
     const isOpen= (index===openIndex) || openIndex==null
  
    return (
     
      <div className={`flex flex-row w-full ${isOpen ? " rounded-md" : ''}`} key={label}>
      <div className='basis-2/12 flex items-center'>
        <span className={`mr-2 ${isOpen ? "" : "invisible"}`}>
          {/* <i className="pi pi-arrow-right text-primary"></i> */}
        </span>
        {isTrue ? (
          <img
            src={Tick}
            id={tooltipId}
            data-pr-tooltip={tooltipText}
            data-pr-position="right"
            data-pr-at="right center"
            style={{ cursor: 'pointer' }}
            className={`icon ${isOpen ? 'opacity-100':'opacity-50'}`}
            alt='tick'
          />
           
        ) 
         : (
          <img
            src={Cross}
            alt='cross'
            id={tooltipId}
         className={`icon ${isOpen ? 'opacity-100':'opacity-50'}`}
            data-pr-tooltip={tooltipText}
            data-pr-position="right"
            data-pr-at="right center"
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
      <div
        id={tooltipId}
        data-pr-tooltip={tooltipText}
        style={{ cursor: 'pointer' }}
        data-pr-position="left"
        data-pr-at="right-80 center"
        data-pr-my="left center-2"
        className={`basis-8/12 md:text-[20px] font-bold text-[15px] ${isTrue && isOpen ? 'text-green opacity-100' : isTrue && !isOpen ? 'text-green opacity-50 font-normal' : !isTrue && isOpen ? 'text-red opacity-100' : 'text-red opacity-50 font-normal'}`}
        onClick={()=>{handleSelectAttribute(index)}}

      >
        
        {label}
      </div>
      <div className='basis-2/12'>
        <div
          id={tooltipId}
          style={{ cursor: 'pointer' }}
          className={`h-6 my-1 ${isSelfInterest ? 'w-12' : 'w-6'} ${isTrue && isOpen ? 'bg-green opacity-100' : isTrue && !isOpen ? 'bg-green opacity-50' : !isTrue && isOpen ? 'bg-red opacity-100' : 'bg-red opacity-50'}`}
          data-pr-tooltip={tooltipText}
        ></div>
        <Tooltip target={`#${tooltipId}`} />
      </div>
    </div>
    );
  };

  

  return (
     

      
          

        
        <div className="w-full space-y-2  mt-4 mb-3  "> 
            <div className='flex flex-col'>
             <div className="bg-secondary drop-shadow-xl rounded-lg md:p-12 md:flex md:flex-row md:items-center flex flex-col items-start relative px-2 " >
               <div className='w-full flex md:flex-row flex-col'onMouseEnter={() => setHovered(true)}
                   onMouseLeave={() => setHovered(false)}>
               <div className='w-full md:my-2 mr-5 mt-10 '
                   >
                
             {  
             submitted ?   
             <div
           
              >
             <h1
               className='font-extrabold text-[25px] px-5 py-2 text-primary hover:underline hover:decoration-dotted cursor-pointer'
               onClick={handleTitleClick}
             >
               {inputValue}
               {hovered && <img src={pen} alt='edit' onClick={handleTitleClick} className='inline mx-4 mb-1' />}
             </h1>
           </div>:
             
             <InputTextarea 
                placeholder='Enter title'
                autoResize value={inputValue} 
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyPress}
                className='w-full md:h-12 px-5 py-2  rounded-md  '
               ref={inputRef} />

                 
}
                 </div>
            
                <div className=  {`  md: py-2 ${progress ? 'cursor-not-allowed opacity-50' : ''}`}>
                { 
          !submitted && (
            <Button
              onClick={handleSubmit}
              disabled={progress}
              label="Improve"
              icon="pi pi-bolt"
              iconPos="right"
              className='rounded-md my-auto'
            />
          )
        }
                </div>
                </div>
              <div className='absolute top-1 right-1 '>
              <Tooltip target=".close" />
                  { props.index!==0 &&  <Button icon=" pi pi-times" rounded text raised severity="danger" aria-label="Cancel" 
                     tooltip='Delete'
                     className="bg-transparent shadow-none text-red-500   rounded-full p-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 hover:text-black " 
                     onClick={() => props.handleDeleteCell(props.index)}/>
                    }
                     
              </div>
            </div>

        <div className='flex flex-col bg-secondary  drop-shadow-xl rounded-lg py-3'>
          <div className='flex md:flex-row md:max-w-4xl md:px-12  flex-col w-full '> {/* Output div */}
              {errorMessage && <div className='text-md p-2 m-2 text-primary'>
                   
                ${errorMessage}
                
                </div>}
              <div className='md:basis-1/2 py-5 basis-full'> {/* left part */}
                <div className='flex flex-col space-y-2 px-5'>
                {!progress && response && !response.error && Object.entries(response).map(([key, value], index) =>
                    renderAttribute(value, key, index)
                )}

                  {response && response.error && <div className="text-red-500">{response.error}</div>}
                </div>
              </div>



              <div className='md:basis-1/2 basis-full relative py-5'> {/* right part */}
              {!progress && response && isMediumScreen && (
                    <div className='absolute right-0 top-[-35px]'>
                      <Toggle onSelect={setSelectedView} />
                    </div>
                  )}
                  {!progress && response && !response.error && data && (
                    selectedView === 'Scores' ? (
                    
                      <PieChart data={data} outerRadius={130} innerRadius={110} id={props.index} />
                      
                    ) : (
                      <div className='ml-9'>
                         <Accordion sendDataToParent={handleOpenIndex} activeIndex={openIndex} />
                         
                      </div>
                    )
                  )}
              </div>
              
             
           

            </div>
                  <div className= "mx-auto mb-2">

                      <Tooltip target=".addCell"/>
                        <img  
                      src={plus}
                      alt='plus'
                      data-pr-tooltip='New Cell'
                      className= "addCell w-24 h-12 cursor-pointer transition duration-300 transform hover:scale-105"
                      onClick={() => props.handleAddCell(props.index)}
                    />
                  </div>
            </div>
      
   
             
            
          </div>
       
        </div>
        
 
 
  );
}

export default Cell;
