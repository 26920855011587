import React, { useEffect, useState } from 'react';
import AccordionItem from './AccordionItem';
import DOMPurify from 'dompurify';

const Accordion = ({ sendDataToParent,activeIndex }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const accordionItems = [
    {
      title: "You can <b>get rid of money worries</b> if you follow this simple plan",
      content: `  
      <div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
                <svg xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 576 512"
                width="15" 
                height="15"
                >
                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
                </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Definition</p>
        <p class="text-[14px]">  Offers some <b>help or  addresses the  </b>  reader's pain.</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline ">
        <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 384 512"
        width="15" 
        height="15"
        >
        <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
        </svg>
        
        </div>
      </div>
      <div class="col-span-3 -ml-14 ">
        <p class="block  mt-2">Example</p>
        
        <p class="mt-2 mb-2"><em>"You can <b>get rid of money worries</b> if you follow this simple plan."</em></p>
      </div>
    
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
        
        <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 512"
         width="15" 
         height="15"
         >
         <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free 
         Copyright 2024 Fonticons, Inc.-->
         <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
        </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 ">
        <p class="block text-[14px]">Explanation</p>
        <p class="text-[14px]"> The phrase <b>get rid of money worries</b> addresses the individual’s desire to improve their financial situation.</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
        width="15"
        height="15"
        ><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
        </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 mt-2">
        <p class="block">More</p>
        <p class="mt-2"> <em> Give me 5 days and I'll give you the secret of a <b>Magnetic Personality</b></em>.</p>
        <p class="mt-2"><em>Announcing a new course for men who <b>want to be independent</b> in the next five years</em>.</p>
      </div>
    </div>
              
   
 
    `
    },
    {
      title: "Do you make <b>these mistakes</b> in English?",
      content: `<div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
                <svg xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 576 512"
                width="15" 
                height="15"
                >
                <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
                </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Definition</p>
        <p class="text-[14px]">Intrigues Reader by hinting at valuable information.</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline ">
        <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 384 512"
        width="15" 
        height="15"
        >
        <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
        </svg>
        
        </div>
      </div>
      <div class="col-span-3 -ml-14 ">
        <p class="block  mt-2">Example</p>
        
        <p class="mt-2 mb-2"><em>"Do you make <b>these mistakes</b> in English?"</em></p>
      </div>
    
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
        
        <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 512"
         width="15" 
         height="15"
         >
         <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free 
         Copyright 2024 Fonticons, Inc.-->
         <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
        </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 ">
        <p class="block text-[14px]">Explanation</p>
        <p class="text-[14px]">The above title intrigues the reader by withholding  what “these” mistakes are. This can be valuable to someone who wishes to improve their English.</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
        width="15"
        height="15"
        ><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
        </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 mt-2">
        <p class="block">More</p>
        <p class="mt-2"> <b><em>How a fool stunt</b> made me a star salesman</em>.</p>
        <p class="mt-2"> <b><em>How I improved</b> my memory in one evening</em>.</p>
      </div>
    </div>
    `

    },
    {
      title: "<b>Announcing</b> a new home money making plan",
      content: ` <div class="grid grid-cols-4 gap-0">
  <div class="col-span-1 w-2 mt-1">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 576 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block text-[14px]">Definition</p>
    <p class="text-[14px]"> Uses words that are suggestive of news like introducing, new, discovered, announcing.</p>
  </div>

  <div class="col-span-1 w-2 mt-3">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 384 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block  mt-2">Example</p>
    <p class="mt-2 mb-2"><em>"Discovered—a new kind of hand cleaner"</em></p>
  </div>

  <div class="col-span-1 w-2 mt-1">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 512 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block text-[14px]">Explanation</p>
    <p class="text-[14px]">The title above used the word <b>Discovered</b>, which is suggestive of news.</p>
  </div>

  <div class="col-span-1 w-2 mt-3">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 576 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14 mt-2">
    <p class="block">More</p>
    <p class="mt-2"><em><b>New </b> features of the Ford truck.</em></p>
    <p class="mt-2"> <em><b>Announcing</b> groundbreaking AI Model that predicts earthquakes weeks in advance.</em></p>
  </div>
</div>

    `
    },
    {
      title: "How I improved my memory in <b> one evening</b>",
      content: ` <div class="grid grid-cols-4 gap-0">
  <div class="col-span-1 w-2 mt-1">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 576 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block text-[14px]">Definition</p>
    <p class="text-[14px]">Implies a quick, simple or easy way to achieve something .</p>
  </div>

  <div class="col-span-1 w-2 mt-3">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 384 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block  mt-2">Example</p>
    <p class="mt-2 mb-2"><em>" How I improved my memory in <b>one evening</b>"</em></p>
  </div>

  <div class="col-span-1 w-2 mt-1">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 512 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block text-[14px]">Explanation</p>
    <p class="text-[14px]"> Title above offers some solution within a <b>very short timespan</b>.</p>
  </div>

  <div class="col-span-1 w-2 mt-3">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 576 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14 mt-2">
    <p class="block">More</p>
    <p class="mt-2"><em>You can get rid of money worries if you follow this <b>simple plan</b></em>.</p>
    <p class="mt-2"> <em>Corn gone in <b>5 days</b> or money back </em>.</p>
  </div>
</div>
    
     `
    },
    {
      title: "Wanted: Your services as a <b>high paid </b> real-estate specialis",
      content: `
       
  <div class="grid grid-cols-4 gap-0">
  <div class="col-span-1 w-2 mt-1">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 576 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block text-[14px]">Definition</p>
    <p class="text-[14px]">Implies a <b>transformative experience or change </b>in the reader's life .</p>
  </div>

  <div class="col-span-1 w-2 mt-3">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 384 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block  mt-2">Example</p>
    <p class="mt-2 mb-2"><em>"Wanted—your services as a <b> high-paid </b> real estate specialist"</em></p>
  </div>

  <div class="col-span-1 w-2 mt-1">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 512 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14">
    <p class="block text-[14px]">Explanation</p>
    <p class="text-[14px]">The title above offers an upgrade in the reader’s life through a <b>high-paying job</b>.</p>
  </div>

  <div class="col-span-1 w-2 mt-3">
    <div class="text-xl inline">
      <svg xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 576 512" 
           width="15" 
           height="15">
        <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
      </svg>
    </div>
  </div>
  <div class="col-span-3 -ml-14 mt-2">
    <p class="block">More</p>
    <p class="mt-2"><em> How a fool stunt <b>made me a star salesman</b></em>.</p>
    <p class="mt-2"> <em> The deaf <b>now hear whispers</b>. </em>.</p>
  </div>
</div>
  
      
      `
    },
    {
      title: "How a fool stunt <b>made me</b> a star salesman",
      content: `
      <div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Definition</p>
        <p class="text-[14px]">Shares something that the <b>author had gone through.</b></p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 384 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block  mt-2">Example</p>
        <p class="mt-2 mb-2"><em> "How a fool stunt <b>made me a star salesman</b>".</em></p>
      </div>
    
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 512 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Explanation</p>
        <p class="text-[14px]">In the above title, the author recounts their journey to becoming a top salesman .</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 mt-2">
        <p class="block">More</p>
        <p class="mt-2"><em> How <b>I improved </b>my memory in one evening.</em></p>
        <p class="mt-2"> <em> <b>I gambled</b> 4$ and won $35,840 in 2 year . </em></p>
      </div>
    </div>
      
      
      `
    },
    {
      title: "Corn gone in <b>5 days</b> or money back",
      content: `
      <div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Definition</p>
        <p class="text-[14px]">Specifies a <b>time frame</b> for achieving something .</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 384 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block  mt-2">Example</p>
        <p class="mt-2 mb-2"><em>"Corn Gone in <b>5 days</b> or money back"</em></p>
      </div>
    
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 512 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Explanation</p>
        <p class="text-[14px]">  The title clearly states <b>5 days required </b>to get a cure.</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 mt-2">
        <p class="block">More</p>
        <p class="mt-2"><em>Give me <b>5 days</b> and I'll give you the secret of a magnetic personality</em>.</p>
        <p class="mt-2"> <em>  How I improved my memory in <b> one evening</b>. </em></p>
      </div>
    </div>
      
    `
    },
    {
      title: "The deaf now <b>hear whispers</b>",
      content: `
      <div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Definition</p>
        <p class="text-[14px]">Clearly states a specific and desirable benefit that <b>meets the reader's needs</b>.
        </p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 384 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block  mt-2">Example</p>
        <p class="mt-2 mb-2"><em>"The <b>deaf now hear whispers</b>"</em></p>
      </div>
    
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 512 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Explanation</p>
        <p class="text-[14px]">The title above highlights the strong desire of a deaf person to hear.</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 mt-2">
        <p class="block">More</p>
        <p class="mt-2"><em>You can <b>get rid of money worries</b> if you follow this simple plan .</em></p>
        <p class="mt-2"> <em> Follow this financial plan to <b>retire at an early age </b>.</em></p>
      </div>
    </div>
      
    `
    },
    {
      title: "Learn this trick to <b>earn 2 crore in a month</b>",
      content: `  <div class="grid grid-cols-4 gap-0">
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 80v48c0 17.7 14.3 32 32 32H48 96V80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48V384c0 35.3 28.7 64 64 64s64-28.7 64-64v-5.3c0-32.4 26.3-58.7 58.7-58.7H480V128c0-53-43-96-96-96H112zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16H314.7c-14.7 0-26.7 11.9-26.7 26.7V384c0 53-43 96-96 96H368h96z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Definition</p>
        <p class="text-[14px]">Makes  <b>extreme or hyperbolic claims</b> to grab attention, often at the expense of credibility .</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 384 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block  mt-2">Example</p>
        <p class="mt-2 mb-2"> <em>"Learn this trick to <b>earn 2 crore in a month</b>"</em></p>
      </div>
    
      <div class="col-span-1 w-2 mt-1">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 512 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14">
        <p class="block text-[14px]">Explanation</p>
        <p class="text-[14px]">The title above makes an <b>unrealistic claim </b> of earning 2 crores with some simple trick .</p>
      </div>
    
      <div class="col-span-1 w-2 mt-3">
        <div class="text-xl inline">
          <svg xmlns="http://www.w3.org/2000/svg" 
               viewBox="0 0 576 512" 
               width="15" 
               height="15">
            <!-- Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path fill="#4c102a" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/>
          </svg>
        </div>
      </div>
      <div class="col-span-3 -ml-14 mt-2">
        <p class="block">More</p>
        <p class="mt-2"><em>Revolutionary serum erases wrinkles in <b>just 60 seconds</b>!</em></p>
        <p class="mt-2"> <em>  "Instant memory upgrade: <b>Remember everything you read</b>!</em></p>
      </div>
    </div>
      
    
    `
    }
  ];
  useEffect(() => {
    setOpenIndex(activeIndex);
  }, [activeIndex]);
  const handleToggle = (index) => {
    
    setOpenIndex(openIndex === index ? null : index);
    sendDataToParent(openIndex === index ? null : index)
 
  };

  return (
    <div className="max-w-md mx-auto bg-transparent rounded-lg -mt-2">
      {openIndex === null ? (
        accordionItems.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            isOpen={false}
            onClick={() => handleToggle(index)}
          />
        ))
      ) : (
        <AccordionItem
          title={accordionItems[openIndex].title}
          isOpen={true}
          onClick={() => handleToggle(openIndex)}
        >
          <div className='text-black'>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(accordionItems[openIndex].content) }}></p>
          </div>
        </AccordionItem>
      )}
    </div>
  );
};

export default Accordion;
