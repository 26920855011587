import React, { useState } from "react";
import { SelectButton } from 'primereact/selectbutton';
import 'primeicons/primeicons.css';
import { Tooltip } from 'primereact/tooltip';

export default function ToggleButton({ onSelect }) {
  const options = [
    { 
      label: <i className="pi pi-chart-bar scores mr-2" data-pr-tooltip="Scores" data-pr-position="left"
       data-pr-at="left-20 top"
     
      />,
      value: 'Scores' 
    },
    { 
      label: <i className="pi pi-list examples mr-2" data-pr-tooltip="Examples" data-pr-position="right"
      data-pr-at="right+25 top"
      />,
      value: 'Examples' 
    }
  ];

  const [value, setValue] = useState(options[0].value);

  const handleChange = (e) => {
    let prev=value
    if(prev==="Scores")
      {setValue("Examples");
    onSelect("Examples")}
    else
    {
      setValue("Scores")
      onSelect("Scores")
    }
        
    
  };
  

  return (
    <div className="card flex justify-content-center items-center">
      <Tooltip target=".scores" />
      <Tooltip target=".examples" />
      <SelectButton 
        value={value} 
        onChange={handleChange} 
        options={options} 
        className="rounded-md"
        itemTemplate={(option) => (
          <div className="flex items-center rounded-xl">
            {option.label}
          </div>
        )}
      />
    </div>
  );
}
