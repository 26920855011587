import React from 'react'
 
// import Colab from './Colab'
import { useState } from 'react';
import Logo from "./Assets/logo.svg"
import Cell from './Cell';
import Colab from './Colab';
const Home = ({ setProgressPercent }) => {
    const [cells, setCells] = useState([0]);

    const handleAddCell = (index) => {
      const newCells = [...cells];
      newCells.splice(index + 1, 0, cells.length);
      setCells(newCells);
    };
  
    const handleDeleteCell = (index) => {
      const newCells = cells.filter((cellIndex, i) => i !== index);
      setCells(newCells);
    };
   


  return (
    <div className="min-h-screen flex flex-col py-3 px-8 mt-3 mb-6">
            <div className='mx-auto'>
    <div className='flex flex-col space-y-4  '>
        <div className=' md:w-96'>
            <div className="flex flex-row w-full h-20  ">
                <img className="" src={Logo} alt="Logo" />
                <span className='font-modulus text-4xl text-primary my-auto'>Content Pilot</span>
            </div>
        </div>
      <div className='space-y-10 max-w-4xl md:min-w-[700px] tab:min-w-[896px] mb-5'>
     {cells.map((cellIndex, index) => (
          <Cell
            setProgressPercent={setProgressPercent}
            key={cellIndex}
            index={index}
            handleAddCell={handleAddCell}
            handleDeleteCell={handleDeleteCell}  
          />
        ))}
        </div>
    </div>
    </div>
    </div>
  )
}

export default Home
