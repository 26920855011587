 import React, { useState } from 'react'
 
import LoadingBar from 'react-top-loading-bar'
import Home from './Home';
 
// import Accordion from './Accordion';
 const App = () => {

   
  const [progressPercent,setProgressPercent]=useState(0);
   return (<>
      <LoadingBar
        color='#4c102a'
        height={5}
        progress={progressPercent}
        onLoaderFinished={()=>setProgressPercent(0)}

      />
       
      
        <Home setProgressPercent={setProgressPercent}/>
        
      </>
   )
 }
 
 export default App
 