import React from 'react';
import DOMPurify from 'dompurify';

const AccordionItem = ({ title, isOpen, onClick,children}) => {
  const extractTextContent = (element) => {
    if (typeof element === 'string') {
      return element;
    }
    if (React.isValidElement(element)) {
      return React.Children.toArray(element.props.children)
        .map(extractTextContent)
        .join('');
    }
    return '';
  };

  const truncateElement = (element, maxLength) => {
    let length = 0;
    
    const traverse = (children) => {
      if (!children) return null;
      
      return React.Children.map(children, (child) => {
        if (typeof child === 'string') {
          if (length + child.length > maxLength) {
            return child.slice(0, maxLength - length) + '...';
          } else {
            length += child.length;
            return child;
          }
        } else if (React.isValidElement(child)) {
          const truncatedChildren = traverse(child.props.children);
          return React.cloneElement(child, { ...child.props, children: truncatedChildren });
        }
        return child;
      });
    };
    
    return traverse(element);
  };

  const textContent = extractTextContent(title);
  const truncatedTitle = textContent.length > 39
    ? truncateElement(title, 39)
    : title;

  return (
    <div>
      <button
        className="w-full text-left p-2 focus:outline-none"
        onClick={onClick}
      >
        <div className="flex justify-between items-center md:mt-[1px]">
          <span className="font-medium text-gray-900">
            {isOpen 
              ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
              : <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncatedTitle) }} />
            }
          </span>
          <svg
  className={`w-5 h-5 transform transition-transform text-primary font-bold  ${isOpen && textContent.length > 47 ? 'rotate-180 mb-5 ml-3' : isOpen ? 'rotate-180' : 'rotate-0'}
  `}
  fill="none"
  stroke="currentColor"
  
  strokeLinecap="round"
  strokeLinejoin="round"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
 

            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </button>
      {isOpen && <div className="p-4 text-gray-700">{children}</div>}
    </div>
  );
};

export default AccordionItem;
