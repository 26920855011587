import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function PieChart(props) {
  const { data, outerRadius, innerRadius, id } = props;
  const tooltipRef = useRef(null);
  
  const margin = { top: 50, right: 50, bottom: 50, left: 50 };
  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;

  useEffect(() => {
    drawChart();
  }, [data]);

  function drawChart() {
    // Remove the old svg
    d3.select(`#pie-container${id}`)
      .select('svg')
      .remove();

    // Create new svg
    const svg = d3
      .select(`#pie-container${id}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const colorScale = d3.scaleOrdinal()
      .domain([true, false])
      .range(['#019501', '#fa0909']);

    const arcGenerator = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(10); 

    const pieGenerator = d3.pie()
      .padAngle(0.015)
      .value((d) => d.value);

    const arcs = pieGenerator(data);

    // Append arcs
    const paths = svg.selectAll('path')
      .data(arcs)
      .enter()
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (d) => colorScale(d.data.attribute))
      .style('stroke', '#ffffff')
      .style('stroke-width', 0)
      .attr('data-pr-tooltip', (d) => {
        if (d.data.attribute && d.data.label === "Self-Interest") {
          return `${d.data.label} factor found. 2 points given.`;
        } else if (d.data.attribute) {
          return `${d.data.label} factor found. 1 point given.`;
        } else if (d.data.label === "No Exaggeration") {
          return `Exaggeration factor found. No point given.`;
        } else {
          return `${d.data.label} factor not found. No point given.`;
        }
      })
      .attr('data-pr-position', (d) => {
        const midAngle = (d.startAngle + d.endAngle) / 2;
        if (midAngle >= 0 && midAngle < Math.PI / 2) {
          return 'right';
        } else if (midAngle >= Math.PI / 2 && midAngle < 5 * Math.PI / 6) {
          return 'right';
        } else if (midAngle >= 5 * Math.PI / 6 / 2 && midAngle < 7 * Math.PI / 6) {
          return 'bottom';
        } else if (midAngle >= Math.PI && midAngle < 1.5 * Math.PI) {
          return 'left';
        } else if (midAngle >= 1.8 * Math.PI && midAngle < 2 * Math.PI) {
          return 'top';
        } else {
          return 'left';
        }
      })
      .style('cursor', 'pointer')
      .on('mouseover', function (event, d) {
        tooltipRef.current.show(event, { target: this });
        d3.select(this).style('opacity', 0.7);
      })
      .on('mouseout', function (event, d) {
        tooltipRef.current.hide(event, { target: this });
        d3.select(this).style('opacity', 1);
      });

    const trueData = data.filter(d => d.attribute === true);
    const sumOfTrueValues = d3.sum(trueData, d => d.value);
    const sum = sumOfTrueValues / 10;

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('fill', '#000')
      .style('font-size', '20px')
      .text(`${sum} / 10`);
  }

  return (
    <div>
      <div id={`pie-container${id}`} className='flex flex-row justify-center'/>
      <Tooltip ref={tooltipRef} />
    </div>
  );
}

export default PieChart;
